<template>
  <div>
    <h3 class="center">Средняя зарплата</h3>
    <div class="heapR">
      <div class="heap heapR">
        <div class="heap-block">
          <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
        </div>
        <div class="heap-block">
          <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
        </div>
        <div class="heap-block">
          <PeopleSelector
            label="Бариска"
            filter="work"
            :id_peopleIn="this.id_people"
            @change="id_people = parseInt($event)"
          />
        </div>
        <div class="heap-block">
          <div @click.prevent="tableDataRetrieve" class="high pointer">
            <button class="btn" ref="btn">Обновить</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading">
      <Loader />
    </div>

    <div v-else>
      <!-- <div class="reminder smalltext">работает сортировка по столбцам</div> -->
      <table class="t_table">
        <tr class="noselect"></tr>
        <tr>
          <th>Дата</th>
          <th>Сумма</th>
          <th>Пояснение к начислению</th>
        </tr>
        <!-- data rows -->
        <tr class="user" v-for="row in data" :key="row.id">
          <td>{{ dateFormatVarious(row.date) }}</td>
          <td class="R">{{ numFormat(row.accrued) }}</td>
          <td class="L">{{ commentDecorate(row.comment, row.date_begin, row.date_end) }}</td>
        </tr>
        <tr v-if="accruedTotal">
          <td>Итого, руб</td>
          <td>
            <b>{{ numFormat(accruedTotal) }}</b>
          </td>
          <td></td>
        </tr>
        <tr v-if="days">
          <td>Итого, дней</td>
          <td>{{ days }}</td>
          <td></td>
        </tr>

        <tr v-if="accruedTotal">
          <td>Среднее, руб</td>
          <td>{{ numFormat(avgSal) }}</td>
          <td class="L">
            По формуле {{ this.accruedTotal }} / {{ this.days }} * {{ this.daysPerMonth }}
          </td>
        </tr>
        <template v-if="data.length == 0">
          <tr>
            <td colspan="300" class="center">нет данных по выбранным параметрам</td>
          </tr>
        </template>
        <!-- <template v-else>
          <tr v-for="(el, i) in sumByBank" :key="i">
            <td colspan="6"></td>
            <td>{{ el.bank }}</td>
            <td></td>
            <td class="R">{{ numFormat(el.sum) }}</td>
          </tr>
          <tr>
            <td colspan="8" class="R">Итого к выплате</td>
            <td class="R">
              <b>{{ numFormat(pay_amountTotal) }}</b>
            </td>
          </tr>
        </template> -->
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
tr.user:hover td {
  /* cursor: pointer; */
  background-color: antiquewhite;
}
td.user {
  cursor: pointer;
}
td.crutch:hover {
  cursor: pointer;
  background-color: antiquewhite;
}
tr.calculating td {
  background-color: antiquewhite;
}
td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.num {
  text-align: right;
}
td.R {
  text-align: right;
}
td.L {
  text-align: left;
}
</style>
<script>
import Loader from "@/components/Loader.vue";
import PeopleSelector from "@/components/PeopleSelector.vue";
import DateSelector from "@/components/DateSelector.vue";
import { request } from "@/components-js/requestSrv";
import { dateFormatJS, dateFormatVarious } from "@/components-js/dateFormat";
import { numFormat } from "@/components-js/numberFormat";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  components: { Loader, DateSelector, PeopleSelector },
  data() {
    return {
      loading: false,
      days: 0,
      daysPerMonth: 28,
      avgSal: 0,
      data: [],

      date_begin: null,
      date_end: null,
      id_people: null,
    };
  },

  mounted() {
    // set default values for dates and point
    const d = new Date();
    this.date_begin = this.date_end = dateFormatJS(d);

    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "id_people");
    if (tmp) this.id_people = tmp;

    this.tableDataRetrieve();
  },

  computed: {
    accruedTotal: function() {
      let res = 0;
      this.data.forEach(el => {
        res += parseInt(el.accrued);
      });
      return res;
    },

    // averageSal: function () {
    //   const days = (new Date(this.date_end) - new Date(this.date_begin)) / 1000 / 60 / 60 / 24;
    //   console.log(this.accruedTotal, days);

    //   return Math.round((this.accruedTotal / days) * 28);
    // },
  },

  //   sumByBank: function () {
  //     const perBank = [];

  //     this.data.forEach((el) => {
  //       if (el.status == this.status) {
  //         let check = false;
  //         perBank.forEach((b) => {
  //           if (b.bank == el.bank) check = true;
  //         });

  //         if (!check) {
  //           perBank.push({ bank: el.bank, sum: 0 });
  //         }

  //         perBank.forEach((b) => {
  //           if (b.bank == el.bank) b.sum += parseInt(el.pay_amount);
  //         });
  //       }
  //     });
  //     return perBank;
  //   },
  // },

  methods: {
    commentDecorate(comment, date_begin, date_end) {
      // if period is empty
      if (!date_begin || !date_end) return comment;
      // otherwise add it
      return (
        comment +
        " (" +
        this.dateFormatVarious(date_begin, 1) +
        " - " +
        this.dateFormatVarious(date_end, 1) +
        ")"
      );
    },

    numFormat(n) {
      return numFormat(n);
    },

    dateFormatJS(d) {
      return dateFormatJS(d);
    },

    dateFormatVarious(d) {
      return dateFormatVarious(d);
    },

    async tableDataRetrieve() {
      if (this.date_begin > this.date_end) this.date_end = this.date_begin;
      this.loading = true;
      this.data = await request(
        "/api/money/salaryaverage",
        "POST",
        {
          date_begin: this.date_begin,
          date_end: this.date_end,
          id_people: this.id_people,
        },
        this.$route.path
      );

      this.loading = false;

      this.days = (new Date(this.date_end) - new Date(this.date_begin)) / 1000 / 60 / 60 / 24;
      this.avgSal = Math.round((this.accruedTotal / this.days) * this.daysPerMonth);
    },
  },
};
</script>
