function numFormat(n) {
  // return "1 024" or "123 456"
  // format number for humans
  if (!n) return n;
  if (n.toString().length < 4) return n.toString();

  let res = "";
  let sign = "";
  if (n < 0) {
    n *= -1;
    sign = "-";
  }

  for (let i = 0; i < n.toString().length; i++) {
    if (!((n.toString().length - i) % 3) && res) res += " ";
    res = res + n.toString()[i];
  }
  return sign + res;
}

export { numFormat };
